<template lang="pug">
#app
  nav.ui.labeled.icon.menu(:class = "{inverted: dark}")
    router-link.pi.item(to="/admin" exact='', v-if="isAdmin")
      i.fat-only.key.icon
      span 管理
    router-link.pi.item(to="/" exact='', v-else)
      i.fat-only.home.icon
      span 首頁
    router-link.item(to="/foods" exact='')
      i.fat-only.book.icon
      span 食譜
    router-link.fat-only.item(to="/tembei" exact='')
      i.fat-only.bullseye.icon
      span 天貝
    router-link.fat-only.item(to="/snacks" exact='')
      i.fat-only.leaf.icon
      span 點心
    router-link.item(to="/videos" exact='')
      i.fat-only.video.icon
      span 影音
    router-link.item(to="/thoughts" exact='')
      i.fat-only.comments.icon
      span 小記
    router-link.item(to="/outer" exact='')
      i.fat-only.sitemap.icon
      span 資源
    router-link.item.thin-only(to="/my" exact='')
        .ui.gold
          span.fat-only 我的
          span 最愛
    // router-link.item(to="/diet" exact='')
      i.fat-only.user.icon
      span 習慣
    // router-link.item.fat-only(to="/baby" exact='')
      i.user.icon
      span 嬰幼兒
      span 食譜
    .right.menu.fat-only
      // a.item(href="https://www.github.com/bestian/food/", target="_blank", rel="noopener noreferrer")
        i.github.icon
        | 原始碼
      router-link.item(to="/my" exact='')
        i.gold.star.icon.fat-only
        .ui.gold
          span.fat-only 我的
          span 最愛
      a.bl.item.fat-only(href = "https://www.facebook.com/sharer/sharer.php?u=https://food.bestian.tw/", target="_blank", rel="noopener noreferrer")
        i.facebook.icon
        | 分享
  #main(:class="{dark: dark}")
    router-view(:foods = "foods", :tembeis = "tembeis", :snacks = "snacks", :title = "title", :tags = "tags", :dark="dark", @i_am_admin="i_am_admin")
  // #d
    .ui.checkbox
      input(type="checkbox", v-model="dark")
      label 黑暗模式
</template>


<script>

import { createHead } from 'unhead'
import { defineComponent } from 'vue'

import { db } from './firebase.js'
import { ref, onValue } from 'firebase/database'

export default defineComponent({
  name: 'App',
  data() {
    return {
      foods: [],
      newdfoods: [],
      specail_tags: ['天貝', '點心'],
      tags: [
        '天貝', '點心',
        '主食', '飯', '麵', '餅', '粥', '米疙瘩', '麵包', '地瓜', '饅頭', '粉絲', '米粉',
        '菜', '小菜', '沙拉',
        '咖哩', '豆子', '米麴', '葉子',
        '點心', '抹醬', '醬',
        '粿', '糕', '麻糬',
        '湯', '飲料',  '水果', '餅乾', '零食'],
      tembeis: [],
      snacks: [],
      dark: false,
      isAdmin: false,
      title: '自然美食DIY',
    };
  },
  // computed: {
  //  foods: function () {
  //    return this.foods_o.slice().reverse()
  //  }
  // }
  created () {
    // Create a global head instance
    createHead()
  },
  mounted () {
    const vm = this;
    let tgs;

    onValue(ref(db, 'foods'), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()
        vm.foods = data
        for (let i = 0; i < vm.foods.length; i += 1) {
          tgs = vm.foods[i].tags || tgs;
          // console.log(tgs);
          for (let j = 0; j < tgs.length; j += 1) {
            // console.log(tgs[j]);
            if (vm.tags.indexOf(tgs[j]) === -1) {
              vm.tags.push(tgs[j]);
            }
          }
        }
      } else {
        console.log('foods database unavaliable')
      }
    })

    onValue(ref(db, 'tembeis'), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()
        vm.tembeis = data
      } else {
        console.log('tembeis database unavaliable')
      }
    })


    onValue(ref(db, 'snacks'), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()
        vm.snacks = data
      } else {
        console.log('snacks database unavaliable')
      }
    })

    /* 
    this.$http.get('/static/foods.json').then((response) => {
      vm.foods = response.data;
      for (let i = 0; i < vm.foods.length; i += 1) {
        tgs = vm.foods[i].tags || tgs;
        // console.log(tgs);
        for (let j = 0; j < tgs.length; j += 1) {
          // console.log(tgs[j]);
          if (vm.tags.indexOf(tgs[j]) === -1) {
            vm.tags.push(tgs[j]);
          }
        }
      }
    });
    this.$http.get('/static/tembeis.json').then((response) => {
      vm.tembeis = response.data;
    });
    this.$http.get('/static/snacks.json').then((response) => {
      vm.snacks = response.data;
    }); */
    if (location.hash) {
      console.log(location.hash)
      this.$router.push(location.hash.replace('#', ''))
    }
  },
  methods: {
    i_am_admin () {
      this.isAdmin = true
    }
  }
});

</script>

<style lang="css">
#app {
  font-family: system-ui,-apple-system,BlinkMacSystemFont,PingFang TC,Microsoft JhengHei,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  margin: 0 !important;
  padding-bottom: 0;
  min-height: 0 !important;
}

.ui.menu {
  margin: 0;
}

.print-only {
  visibility: hidden !important;
  display: none !important;
}

@media print {
  .no-print {
    visibility: hidden !important;
    display: none !important;
    height: 0 !important;
  }
  .print-only {
    visibility: visible !important;
    display: block !important;
  }
  img, div, .card, .column {
    page-break-inside: avoid !important;
  }
}

i.gold {
  color: #dd0 !important;
}

video {
  border-radius: 15px;
  width: 100%;
  -webkit-box-shadow: 0px 5px 10px 2px #5B5B5B;
  box-shadow: 0px 5px 10px 2px #5B5B5B;
}

.ui.card>.image, .ui.cards>.card>.image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

a, button, .clickable {
  cursor: pointer !important;
}

.ui.card {
  box-shadow: grey 0px 1px 5px 1px;
}

@media screen and (max-width: 600px) {
  .fat-only {
    display: none !important;
  }
  .button {
    max-width: 100% !important;
  }
  .item {
    min-width: 0 !important;
  }
}

#d {
  position: fixed;
  bottom: 2em;
  left: 1em;
  background-color: white;
  padding: .5em;
  border-radius: 15px;
}

@media screen and (max-width: 991px) {
  .fater-only {
    display: none !important;
  }
}

@media screen and (min-width: 601px) {
  .thin-only {
    display: none !important;
  }
}

.router-link-exact-active {
  background-color: #ddf !important;
}

img.float.left {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 2em;
  height: 10em;
}

img.small {
  /* border-radius: 50%; */
  height: 6em;
}

@media screen and (max-width:600px) {
  img.float.left {
    left: 0;
    height: 5em;
  }
}

.disabled {
  pointer-events: none;
}

.bl {
  color: #009 !important;
}

.pi {
  color: #990 !important;
}

#main {
  margin-top: 1em;
}

#main.dark, #main.dark .ui.segment, #main.dark .ui.header, #main.dark label {
  background-color: black;
  color: white !important;
}


.description.center {
  text-align: center;
}

.square {
  width: 100%;
  background-size: cover;
  background-position: center;
}

.big.image .square {
  height: 50vmin !important;
}

.small.image .square {
  height: 33vmin !important;
}

.filler {
  flex-grow: 10
}

.card .header {
  white-space: pre-line;
  font-size: 18px;
  padding: 1em;
}

.card .header.long {
  /* font-size: 14px; */
  /* position: relative;
  bottom: 1em; */
}

.animated .card {
  position: relative;
  top: 0;
}
.animated .card:hover {
  top: -2px;
}

.card .square {
  border-radius: 5px 5px 0 0 !important;
}

.card video {
  border-radius: 5px 5px 0 0 !important;
}

.animated .card:hover .ui.header {
  color: blue !important;
}

.animated .item{
  position: relative;
  top: 0;
}
.animated .item:hover {
  top: -2px;
  transition: all 0.3s ease;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.horizontal.list .item {
  width: 15vw;
  height: 15vh;
}

.description {
  text-align: left;
  padding: 0 1em;
}

.floated.left {
  float: left;
}

.ui.eight.column.doubling.row .column {
  margin-top: 1em;
}

a.column.text {
  padding: 5px !important;
}

.ui.card {
  -webkit-box-shadow: 0px 5px 10px 2px #5B5B5B;
  box-shadow: 0px 5px 10px 2px #5B5B5B;
}

i.angle.double.right.arrow.icon {
  float: right;
}

li.item {
  padding-bottom: .82em !important;
}

.ui.four.cards>.card {
    width: calc(25% - 3em);
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
}

@media only screen and (max-width: 767px) {
  .ui.four.doubling.cards>.card {
      width: calc(50% - 2em);
      margin-left: 1em;
      margin-right: 1em;
  }
}

a.ui.button:hover {
  background-color: #ff3;
}

a.ui.button {
  border-radius: 15px;
  border-top: 1px solid #990;
  margin: .2em .2em !important;
  background-color: #FAF6A3;
  color: #060;
}

.ui.label {
  width: 3em;
  background-color: #FAF6A3;
}

.ui.label>a {
  margin: 0 0.4em;
  opacity: 1;
}

.ui.label>a:hover {
  color: black;
}
.ui.label#s {
  background-color: transparent;
}

.block {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.gold {
  color: gold;
}

.fluid {
  width: 100%;
}

.text-left {
  text-align: left;
  margin-left: .4em;
}

.ui.buttons .button {
  margin: 0 !important;
}


</style>
